///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Mini Post */

.cell-container {
  position: relative;

  .description {
    position: absolute;
    padding: 0;
    bottom: 0;
    min-width: 100%;
    border-top: solid 1px _palette(border);

    p {
      font-size: 0.9rem;
      margin: 0;
      min-width: 100%;
      padding: 1.25em;
      background-color: rgba(255,255,255,.7);
      color: #3c3b3b;
      font-family: "Raleway", Helvetica, sans-serif;
      z-index: 1000;
      transition: all 0.1s;
      -webkit-transition: all 0.1s ease-in-out;
    }

    p:hover {
      background-color: rgba(255,255,255,.9);
    }
  }
  
  @media (max-width: 480px) {
    .description {
      position: inherit;
      padding: 0;
      bottom: inherit;
    }
  }
}

.mini-post {
  @include vendor( 'display', 'flex');
  @include vendor( 'flex-direction', 'column');
  background: _palette(bg);
  border: solid 1px _palette(border);
  margin: 0 0 _size(element-margin);

  .image {
    overflow: hidden;
    width: 100%;

    img {
      @include vendor( 'transition', 'transform #{_duration(transition)} ease-out');
      width: 100%;
    }

    &:hover {
      img {
        @include vendor( 'transform', 'scale(1.05)');
      }
    }
  }

  header {
    z-index: 2;
    @include padding(1.25em, 1.25em, (0, 3em, 0, 0));
    min-height: 4em;
    position: relative;
    @include vendor( 'flex-grow', '1');

    h3 {
      font-size: 0.7em;
    }

    .published {
      display: block;
      font-family: _font(family-heading);
      font-size: 0.6em;
      font-weight: _font(weight-heading);
      letter-spacing: _font(kerning-heading);
      margin: -0.625em 0 (_size(element-margin) * 0.85) 0;
      text-transform: uppercase;
    }

    .author {
      position: absolute;
      right: 2em;
      top: 2em;
    }
  }
}

.mini-posts {
  margin: 0 0 _size(element-margin);
  @include breakpoint(large) {
    @include vendor( 'display', 'flex');
    @include vendor( 'flex-wrap', 'wrap');
    width: calc(100% + #{_size(element-margin)});

    > * {
      margin: _size(element-margin) _size(element-margin) 0 0;
      width: calc(50% - #{_size(element-margin)});
    }

    > :nth-child(-n + 2) {
      margin-top: 0;
    }
  }
  @include breakpoint(xsmall) {
    display: block;
    width: 100%;

    > * {
      margin: 0 0 _size(element-margin);
      width: 100%;
    }
  }
}

/* Row */

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
}

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp {
    order: -1;
  }

  .row > .col-1 {
    width: 8.33333%;
  }

  .row > .off-1 {
    margin-left: 8.33333%;
  }

  .row > .col-2 {
    width: 16.66667%;
  }

  .row > .off-2 {
    margin-left: 16.66667%;
  }

  .row > .col-3 {
    width: 25%;
  }

  .row > .off-3 {
    margin-left: 25%;
  }

  .row > .col-4 {
    width: 33.33333%;
  }

  .row > .off-4 {
    margin-left: 33.33333%;
  }

  .row > .col-5 {
    width: 41.66667%;
  }

  .row > .off-5 {
    margin-left: 41.66667%;
  }

  .row > .col-6 {
    width: 50%;
  }

  .row > .off-6 {
    margin-left: 50%;
  }

  .row > .col-7 {
    width: 58.33333%;
  }

  .row > .off-7 {
    margin-left: 58.33333%;
  }

  .row > .col-8 {
    width: 66.66667%;
  }

  .row > .off-8 {
    margin-left: 66.66667%;
  }

  .row > .col-9 {
    width: 75%;
  }

  .row > .off-9 {
    margin-left: 75%;
  }

  .row > .col-10 {
    width: 83.33333%;
  }

  .row > .off-10 {
    margin-left: 83.33333%;
  }

  .row > .col-11 {
    width: 91.66667%;
  }

  .row > .off-11 {
    margin-left: 91.66667%;
  }

  .row > .col-12 {
    width: 100%;
  }

  .row > .off-12 {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0px;
    margin-left: 0px;
  }

    .row.gtr-0 > * {
      padding: 0px 0 0 0px;
    }

    .row.gtr-0.gtr-uniform {
      margin-top: 0px;
    }

      .row.gtr-0.gtr-uniform > * {
        padding-top: 0px;
      }

  .row.gtr-25 {
    margin-top: -6.25px;
    margin-left: -6.25px;
  }

    .row.gtr-25 > * {
      padding: 6.25px 0 0 6.25px;
    }

    .row.gtr-25.gtr-uniform {
      margin-top: -6.25px;
    }

      .row.gtr-25.gtr-uniform > * {
        padding-top: 6.25px;
      }

  .row.gtr-50 {
    margin-top: -12.5px;
    margin-left: -12.5px;
  }

    .row.gtr-50 > * {
      padding: 12.5px 0 0 12.5px;
    }

    .row.gtr-50.gtr-uniform {
      margin-top: -12.5px;
    }

      .row.gtr-50.gtr-uniform > * {
        padding-top: 12.5px;
      }

  .row {
    margin-top: -25px;
    margin-left: -25px;
  }

    .row > * {
      padding: 25px 0 0 25px;
    }

    .row.gtr-uniform {
      margin-top: -25px;
    }

      .row.gtr-uniform > * {
        padding-top: 25px;
      }

  .row.gtr-150 {
    margin-top: -37.5px;
    margin-left: -37.5px;
  }

    .row.gtr-150 > * {
      padding: 37.5px 0 0 37.5px;
    }

    .row.gtr-150.gtr-uniform {
      margin-top: -37.5px;
    }

      .row.gtr-150.gtr-uniform > * {
        padding-top: 37.5px;
      }

  .row.gtr-200 {
    margin-top: -50px;
    margin-left: -50px;
  }

    .row.gtr-200 > * {
      padding: 50px 0 0 50px;
    }

    .row.gtr-200.gtr-uniform {
      margin-top: -50px;
    }

      .row.gtr-200.gtr-uniform > * {
        padding-top: 50px;
      }

  @media screen and (max-width: 1680px) {

    .row {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-items: stretch;
    }

      .row > * {
        box-sizing: border-box;
      }

      .row.gtr-uniform > * > :last-child {
        margin-bottom: 0;
      }

      .row.aln-left {
        justify-content: flex-start;
      }

      .row.aln-center {
        justify-content: center;
      }

      .row.aln-right {
        justify-content: flex-end;
      }

      .row.aln-top {
        align-items: flex-start;
      }

      .row.aln-middle {
        align-items: center;
      }

      .row.aln-bottom {
        align-items: flex-end;
      }

      .row > .imp-xlarge {
        order: -1;
      }

      .row > .col-1-xlarge {
        width: 8.33333%;
      }

      .row > .off-1-xlarge {
        margin-left: 8.33333%;
      }

      .row > .col-2-xlarge {
        width: 16.66667%;
      }

      .row > .off-2-xlarge {
        margin-left: 16.66667%;
      }

      .row > .col-3-xlarge {
        width: 25%;
      }

      .row > .off-3-xlarge {
        margin-left: 25%;
      }

      .row > .col-4-xlarge {
        width: 33.33333%;
      }

      .row > .off-4-xlarge {
        margin-left: 33.33333%;
      }

      .row > .col-5-xlarge {
        width: 41.66667%;
      }

      .row > .off-5-xlarge {
        margin-left: 41.66667%;
      }

      .row > .col-6-xlarge {
        width: 50%;
      }

      .row > .off-6-xlarge {
        margin-left: 50%;
      }

      .row > .col-7-xlarge {
        width: 58.33333%;
      }

      .row > .off-7-xlarge {
        margin-left: 58.33333%;
      }

      .row > .col-8-xlarge {
        width: 66.66667%;
      }

      .row > .off-8-xlarge {
        margin-left: 66.66667%;
      }

      .row > .col-9-xlarge {
        width: 75%;
      }

      .row > .off-9-xlarge {
        margin-left: 75%;
      }

      .row > .col-10-xlarge {
        width: 83.33333%;
      }

      .row > .off-10-xlarge {
        margin-left: 83.33333%;
      }

      .row > .col-11-xlarge {
        width: 91.66667%;
      }

      .row > .off-11-xlarge {
        margin-left: 91.66667%;
      }

      .row > .col-12-xlarge {
        width: 100%;
      }

      .row > .off-12-xlarge {
        margin-left: 100%;
      }

      .row.gtr-0 {
        margin-top: 0px;
        margin-left: 0px;
      }

        .row.gtr-0 > * {
          padding: 0px 0 0 0px;
        }

        .row.gtr-0.gtr-uniform {
          margin-top: 0px;
        }

          .row.gtr-0.gtr-uniform > * {
            padding-top: 0px;
          }

      .row.gtr-25 {
        margin-top: -6.25px;
        margin-left: -6.25px;
      }

        .row.gtr-25 > * {
          padding: 6.25px 0 0 6.25px;
        }

        .row.gtr-25.gtr-uniform {
          margin-top: -6.25px;
        }

          .row.gtr-25.gtr-uniform > * {
            padding-top: 6.25px;
          }

      .row.gtr-50 {
        margin-top: -12.5px;
        margin-left: -12.5px;
      }

        .row.gtr-50 > * {
          padding: 12.5px 0 0 12.5px;
        }

        .row.gtr-50.gtr-uniform {
          margin-top: -12.5px;
        }

          .row.gtr-50.gtr-uniform > * {
            padding-top: 12.5px;
          }

      .row {
        margin-top: -25px;
        margin-left: -25px;
      }

        .row > * {
          padding: 25px 0 0 25px;
        }

        .row.gtr-uniform {
          margin-top: -25px;
        }

          .row.gtr-uniform > * {
            padding-top: 25px;
          }

      .row.gtr-150 {
        margin-top: -37.5px;
        margin-left: -37.5px;
      }

        .row.gtr-150 > * {
          padding: 37.5px 0 0 37.5px;
        }

        .row.gtr-150.gtr-uniform {
          margin-top: -37.5px;
        }

          .row.gtr-150.gtr-uniform > * {
            padding-top: 37.5px;
          }

      .row.gtr-200 {
        margin-top: -50px;
        margin-left: -50px;
      }

        .row.gtr-200 > * {
          padding: 50px 0 0 50px;
        }

        .row.gtr-200.gtr-uniform {
          margin-top: -50px;
        }

          .row.gtr-200.gtr-uniform > * {
            padding-top: 50px;
          }

  }

  @media screen and (max-width: 1280px) {

    .row {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-items: stretch;
    }

      .row > * {
        box-sizing: border-box;
      }

      .row.gtr-uniform > * > :last-child {
        margin-bottom: 0;
      }

      .row.aln-left {
        justify-content: flex-start;
      }

      .row.aln-center {
        justify-content: center;
      }

      .row.aln-right {
        justify-content: flex-end;
      }

      .row.aln-top {
        align-items: flex-start;
      }

      .row.aln-middle {
        align-items: center;
      }

      .row.aln-bottom {
        align-items: flex-end;
      }

      .row > .imp-large {
        order: -1;
      }

      .row > .col-1-large {
        width: 8.33333%;
      }

      .row > .off-1-large {
        margin-left: 8.33333%;
      }

      .row > .col-2-large {
        width: 16.66667%;
      }

      .row > .off-2-large {
        margin-left: 16.66667%;
      }

      .row > .col-3-large {
        width: 25%;
      }

      .row > .off-3-large {
        margin-left: 25%;
      }

      .row > .col-4-large {
        width: 33.33333%;
      }

      .row > .off-4-large {
        margin-left: 33.33333%;
      }

      .row > .col-5-large {
        width: 41.66667%;
      }

      .row > .off-5-large {
        margin-left: 41.66667%;
      }

      .row > .col-6-large {
        width: 50%;
      }

      .row > .off-6-large {
        margin-left: 50%;
      }

      .row > .col-7-large {
        width: 58.33333%;
      }

      .row > .off-7-large {
        margin-left: 58.33333%;
      }

      .row > .col-8-large {
        width: 66.66667%;
      }

      .row > .off-8-large {
        margin-left: 66.66667%;
      }

      .row > .col-9-large {
        width: 75%;
      }

      .row > .off-9-large {
        margin-left: 75%;
      }

      .row > .col-10-large {
        width: 83.33333%;
      }

      .row > .off-10-large {
        margin-left: 83.33333%;
      }

      .row > .col-11-large {
        width: 91.66667%;
      }

      .row > .off-11-large {
        margin-left: 91.66667%;
      }

      .row > .col-12-large {
        width: 100%;
      }

      .row > .off-12-large {
        margin-left: 100%;
      }

      .row.gtr-0 {
        margin-top: 0px;
        margin-left: 0px;
      }

        .row.gtr-0 > * {
          padding: 0px 0 0 0px;
        }

        .row.gtr-0.gtr-uniform {
          margin-top: 0px;
        }

          .row.gtr-0.gtr-uniform > * {
            padding-top: 0px;
          }

      .row.gtr-25 {
        margin-top: -6.25px;
        margin-left: -6.25px;
      }

        .row.gtr-25 > * {
          padding: 6.25px 0 0 6.25px;
        }

        .row.gtr-25.gtr-uniform {
          margin-top: -6.25px;
        }

          .row.gtr-25.gtr-uniform > * {
            padding-top: 6.25px;
          }

      .row.gtr-50 {
        margin-top: -12.5px;
        margin-left: -12.5px;
      }

        .row.gtr-50 > * {
          padding: 12.5px 0 0 12.5px;
        }

        .row.gtr-50.gtr-uniform {
          margin-top: -12.5px;
        }

          .row.gtr-50.gtr-uniform > * {
            padding-top: 12.5px;
          }

      .row {
        margin-top: -25px;
        margin-left: -25px;
      }

        .row > * {
          padding: 25px 0 0 25px;
        }

        .row.gtr-uniform {
          margin-top: -25px;
        }

          .row.gtr-uniform > * {
            padding-top: 25px;
          }

      .row.gtr-150 {
        margin-top: -37.5px;
        margin-left: -37.5px;
      }

        .row.gtr-150 > * {
          padding: 37.5px 0 0 37.5px;
        }

        .row.gtr-150.gtr-uniform {
          margin-top: -37.5px;
        }

          .row.gtr-150.gtr-uniform > * {
            padding-top: 37.5px;
          }

      .row.gtr-200 {
        margin-top: -50px;
        margin-left: -50px;
      }

        .row.gtr-200 > * {
          padding: 50px 0 0 50px;
        }

        .row.gtr-200.gtr-uniform {
          margin-top: -50px;
        }

          .row.gtr-200.gtr-uniform > * {
            padding-top: 50px;
          }

  }

  @media screen and (max-width: 980px) {

    .row {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-items: stretch;
    }

      .row > * {
        box-sizing: border-box;
      }

      .row.gtr-uniform > * > :last-child {
        margin-bottom: 0;
      }

      .row.aln-left {
        justify-content: flex-start;
      }

      .row.aln-center {
        justify-content: center;
      }

      .row.aln-right {
        justify-content: flex-end;
      }

      .row.aln-top {
        align-items: flex-start;
      }

      .row.aln-middle {
        align-items: center;
      }

      .row.aln-bottom {
        align-items: flex-end;
      }

      .row > .imp-medium {
        order: -1;
      }

      .row > .col-1-medium {
        width: 8.33333%;
      }

      .row > .off-1-medium {
        margin-left: 8.33333%;
      }

      .row > .col-2-medium {
        width: 16.66667%;
      }

      .row > .off-2-medium {
        margin-left: 16.66667%;
      }

      .row > .col-3-medium {
        width: 25%;
      }

      .row > .off-3-medium {
        margin-left: 25%;
      }

      .row > .col-4-medium {
        width: 33.33333%;
      }

      .row > .off-4-medium {
        margin-left: 33.33333%;
      }

      .row > .col-5-medium {
        width: 41.66667%;
      }

      .row > .off-5-medium {
        margin-left: 41.66667%;
      }

      .row > .col-6-medium {
        width: 50%;
      }

      .row > .off-6-medium {
        margin-left: 50%;
      }

      .row > .col-7-medium {
        width: 58.33333%;
      }

      .row > .off-7-medium {
        margin-left: 58.33333%;
      }

      .row > .col-8-medium {
        width: 66.66667%;
      }

      .row > .off-8-medium {
        margin-left: 66.66667%;
      }

      .row > .col-9-medium {
        width: 75%;
      }

      .row > .off-9-medium {
        margin-left: 75%;
      }

      .row > .col-10-medium {
        width: 83.33333%;
      }

      .row > .off-10-medium {
        margin-left: 83.33333%;
      }

      .row > .col-11-medium {
        width: 91.66667%;
      }

      .row > .off-11-medium {
        margin-left: 91.66667%;
      }

      .row > .col-12-medium {
        width: 100%;
      }

      .row > .off-12-medium {
        margin-left: 100%;
      }

      .row.gtr-0 {
        margin-top: 0px;
        margin-left: 0px;
      }

        .row.gtr-0 > * {
          padding: 0px 0 0 0px;
        }

        .row.gtr-0.gtr-uniform {
          margin-top: 0px;
        }

          .row.gtr-0.gtr-uniform > * {
            padding-top: 0px;
          }

      .row.gtr-25 {
        margin-top: -6.25px;
        margin-left: -6.25px;
      }

        .row.gtr-25 > * {
          padding: 6.25px 0 0 6.25px;
        }

        .row.gtr-25.gtr-uniform {
          margin-top: -6.25px;
        }

          .row.gtr-25.gtr-uniform > * {
            padding-top: 6.25px;
          }

      .row.gtr-50 {
        margin-top: -12.5px;
        margin-left: -12.5px;
      }

        .row.gtr-50 > * {
          padding: 12.5px 0 0 12.5px;
        }

        .row.gtr-50.gtr-uniform {
          margin-top: -12.5px;
        }

          .row.gtr-50.gtr-uniform > * {
            padding-top: 12.5px;
          }

      .row {
        margin-top: -25px;
        margin-left: -25px;
      }

        .row > * {
          padding: 25px 0 0 25px;
        }

        .row.gtr-uniform {
          margin-top: -25px;
        }

          .row.gtr-uniform > * {
            padding-top: 25px;
          }

      .row.gtr-150 {
        margin-top: -37.5px;
        margin-left: -37.5px;
      }

        .row.gtr-150 > * {
          padding: 37.5px 0 0 37.5px;
        }

        .row.gtr-150.gtr-uniform {
          margin-top: -37.5px;
        }

          .row.gtr-150.gtr-uniform > * {
            padding-top: 37.5px;
          }

      .row.gtr-200 {
        margin-top: -50px;
        margin-left: -50px;
      }

        .row.gtr-200 > * {
          padding: 50px 0 0 50px;
        }

        .row.gtr-200.gtr-uniform {
          margin-top: -50px;
        }

          .row.gtr-200.gtr-uniform > * {
            padding-top: 50px;
          }

  }

  @media screen and (max-width: 736px) {

    .row {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-items: stretch;
    }

      .row > * {
        box-sizing: border-box;
      }

      .row.gtr-uniform > * > :last-child {
        margin-bottom: 0;
      }

      .row.aln-left {
        justify-content: flex-start;
      }

      .row.aln-center {
        justify-content: center;
      }

      .row.aln-right {
        justify-content: flex-end;
      }

      .row.aln-top {
        align-items: flex-start;
      }

      .row.aln-middle {
        align-items: center;
      }

      .row.aln-bottom {
        align-items: flex-end;
      }

      .row > .imp-small {
        order: -1;
      }

      .row > .col-1-small {
        width: 8.33333%;
      }

      .row > .off-1-small {
        margin-left: 8.33333%;
      }

      .row > .col-2-small {
        width: 16.66667%;
      }

      .row > .off-2-small {
        margin-left: 16.66667%;
      }

      .row > .col-3-small {
        width: 25%;
      }

      .row > .off-3-small {
        margin-left: 25%;
      }

      .row > .col-4-small {
        width: 33.33333%;
      }

      .row > .off-4-small {
        margin-left: 33.33333%;
      }

      .row > .col-5-small {
        width: 41.66667%;
      }

      .row > .off-5-small {
        margin-left: 41.66667%;
      }

      .row > .col-6-small {
        width: 50%;
      }

      .row > .off-6-small {
        margin-left: 50%;
      }

      .row > .col-7-small {
        width: 58.33333%;
      }

      .row > .off-7-small {
        margin-left: 58.33333%;
      }

      .row > .col-8-small {
        width: 66.66667%;
      }

      .row > .off-8-small {
        margin-left: 66.66667%;
      }

      .row > .col-9-small {
        width: 75%;
      }

      .row > .off-9-small {
        margin-left: 75%;
      }

      .row > .col-10-small {
        width: 83.33333%;
      }

      .row > .off-10-small {
        margin-left: 83.33333%;
      }

      .row > .col-11-small {
        width: 91.66667%;
      }

      .row > .off-11-small {
        margin-left: 91.66667%;
      }

      .row > .col-12-small {
        width: 100%;
      }

      .row > .off-12-small {
        margin-left: 100%;
      }

      .row.gtr-0 {
        margin-top: 0px;
        margin-left: 0px;
      }

        .row.gtr-0 > * {
          padding: 0px 0 0 0px;
        }

        .row.gtr-0.gtr-uniform {
          margin-top: 0px;
        }

          .row.gtr-0.gtr-uniform > * {
            padding-top: 0px;
          }

      .row.gtr-25 {
        margin-top: -3.75px;
        margin-left: -3.75px;
      }

        .row.gtr-25 > * {
          padding: 3.75px 0 0 3.75px;
        }

        .row.gtr-25.gtr-uniform {
          margin-top: -3.75px;
        }

          .row.gtr-25.gtr-uniform > * {
            padding-top: 3.75px;
          }

      .row.gtr-50 {
        margin-top: -7.5px;
        margin-left: -7.5px;
      }

        .row.gtr-50 > * {
          padding: 7.5px 0 0 7.5px;
        }

        .row.gtr-50.gtr-uniform {
          margin-top: -7.5px;
        }

          .row.gtr-50.gtr-uniform > * {
            padding-top: 7.5px;
          }

      .row {
        margin-top: -15px;
        margin-left: -15px;
      }

        .row > * {
          padding: 15px 0 0 15px;
        }

        .row.gtr-uniform {
          margin-top: -15px;
        }

          .row.gtr-uniform > * {
            padding-top: 15px;
          }

      .row.gtr-150 {
        margin-top: -22.5px;
        margin-left: -22.5px;
      }

        .row.gtr-150 > * {
          padding: 22.5px 0 0 22.5px;
        }

        .row.gtr-150.gtr-uniform {
          margin-top: -22.5px;
        }

          .row.gtr-150.gtr-uniform > * {
            padding-top: 22.5px;
          }

      .row.gtr-200 {
        margin-top: -30px;
        margin-left: -30px;
      }

        .row.gtr-200 > * {
          padding: 30px 0 0 30px;
        }

        .row.gtr-200.gtr-uniform {
          margin-top: -30px;
        }

          .row.gtr-200.gtr-uniform > * {
            padding-top: 30px;
          }

  }
